export const navLinks = [
  {
    id: 1,
    linkText: "Home",
    url: "/"
  },
  {
    id: 2,
    linkText: "About Us",
    url: "/about-us",

  },

  {
    id: 4,
    linkText: "Residential",
    url: "/residential",
    subLinks: [
      {
        id: 1,
        linkText: "Duplex",
        url: "/property?propertyType=Duplex",
       
      },
      {
        id: 2,
        linkText: "TownHouse",
        url: "/property?propertyType=TownHouse",
       
      },
      {
        id: 3,
        linkText: "Villa",
        url: "/property?propertyType=Villa",
      
      },
      
     
      {
        id: 5,
        linkText: "Apartment",
        url: "/property?propertyType=apartment",
    
      },
     
    ]
  },
  {
    id: 5,
    linkText: "Commercial",
    url: "/commercial",
    subLinks: [
      {
        id: 1,
        linkText: "Office",
        url: "/property?propertyType=office&purpose=Commercial+Rent",
      
      },
      {
        id: 2,
        linkText: "Shop",
        url: "/property?propertyType=shop&purpose=Commercial+Rent",
   
      },
      {
        id: 3,
        linkText: "Warehouse",
        url: "/property?propertyType=Warehouse&purpose=Commercial+Rent",
      
      },
     
     
    ]
  },
  {
    id:6,
    linkText: "Services",
    url: "/services",
  },



  {
    id: 7,
    linkText: "Blog",
    url: "/blog"
  },
  {
    id: 8,
    linkText: "Contact",
    url: "/contact"
  },

];







// miniSubLinks: [
//   {
//     id: 1,
//     linkText: "Rent",
//     url: "/property?propertyType=Apartments&purpose=rent",
//   },
//   {
//     id: 2,
//     linkText: "Sale",
//     url: "/property?propertyType=Apartments&purpose=sale",
//   }
// ]